import { api } from 'boot/axios'

export default {
  async index ({ commit, state }, params) {
    commit('setLoading', true)

    let response = null
    try {
      response = await api.get(
        `/${state.moduleSlug}`,
        {
          params: params,
        },
      )
    } catch (error) {
      // console.error(error)
      commit('setLoading', false)

      throw error
    }

    commit('setData', response.data)
    commit('setLoading', false)

    return response
  },
  async show ({ commit, getters, state }, { id, refresh }) {
    const datum = getters.find(id)
    if (!refresh && datum) {
      return datum
    }

    commit('setLoading', true)

    let response = null
    try {
      response = await api.get(`/${state.moduleSlug}/${id}`)
    } catch (error) {
      // console.error(error)
      commit('setLoading', false)

      throw error
    }

    commit('setDatum', response.data)
    commit('setLoading', false)

    return response
  },
}
