import axios from 'axios'
import Vue from 'vue'

const api = axios.create(
  {
    baseURL: `${process.env.WEB_URL}/api`,
  },
)

Vue.prototype.$axios = axios
Vue.prototype.$api = api

export { axios, api }
