import { get } from 'lodash'
import { Loading, Notify } from 'quasar'
import Vue from 'vue'

let requestCount = 0

function showError (
  error,
  config,
) {
  if (
    !get(
      config.headers,
      'x-show-error',
      true,
    )
  ) {
    return
  }

  if (!error) {
    return
  }

  const notification = {
    icon: 'fas fa-exclamation-triangle',
    message: 'Something went wrong. Please try again.',
    position: 'center',
    progress: true,
    type: 'negative',
  }

  if (error.response) {
    Notify.create(
      {
        ...notification,
        message: get(
          error,
          'response.data.message',
          notification.message,
        ),
      },
    )

    return
  }

  if (error.request) {
    Notify.create(
      {
        ...notification,
        message: 'Request failed. Check your internet connection and please try again.',
      },
    )

    return
  }

  Notify.create(notification)
}

function incrementRequestCount (
  increment,
  config,
) {
  if (
    get(
      config.headers,
      'x-show-loading',
      true,
    )
  ) {
    requestCount += increment
  }

  if (0 === requestCount) {
    Loading.hide()

    return
  }

  Loading.show(
    {
      message: 'Please wait...',
    },
  )
}

Vue.prototype.$api.interceptors.request.use(
  config => {
    incrementRequestCount(
      1,
      config,
    )

    return config
  },
  error => {
    showError(
      error,
      error.config,
    )
    incrementRequestCount(
      -1,
      error.config,
    )

    return Promise.reject(error)
  },
)

Vue.prototype.$api.interceptors.response.use(
  response => {
    incrementRequestCount(
      -1,
      response.config,
    )

    return response
  },
  error => {
    showError(
      error,
      error.config,
    )
    incrementRequestCount(
      -1,
      error.config,
    )

    return Promise.reject(error)
  },
)
