import {get} from 'lodash'

export default function ({ store }) {
  return [
    {
      path: '/',
      component: () => import(
        'layouts/MainLayout.vue'
        ),
      children: [
        {
          path: '',
          component: () => import(
            'pages/index.vue'
            ),
        },
        {
          path: ':locale',
          component: () => import(
            'layouts/RouterView.vue'
            ),
          children: [
            {
              path: '',
              component: () => import(
                'pages/_locale/index.vue'
                ),
            },
            {
              path: 'reset-password',
              component: () => import(
                'pages/_locale/reset-password.vue'
                ),
            },
            {
              path: 'set-password',
              component: () => import(
                'pages/_locale/set-password.vue'
                ),
            },
            {
              path: 'sign-in',
              component: () => import(
                'pages/_locale/sign-in.vue'
                ),
            },
            {
              path: 'sign-up',
              component: () => import(
                'pages/_locale/sign-up.vue'
                ),
            },
            {
              path: 'airport',
              component: () => import(
                'pages/_locale/airport.vue'
                ),
            },
            {
              path: 'airport/:id',
              component: () => import(
                'pages/_locale/airport/_id/index.vue'
                ),
            },
            {
              path: 'group',
              component: () => import(
                'pages/_locale/group.vue'
                ),
            },
            {
              path: 'group/:slug',
              component: () => import(
                'pages/_locale/group/_slug/index.vue'
                ),
            },
            {
              path: 'dashboard',
              beforeEnter: (to, from, next) => {
                if (!get(store, 'state.auth.data.id')) {
                  next('/en-us/sign-in')

                  return
                }

                next()
              },
              component: () => import(
                'layouts/RouterView.vue'
                ),
              children: [
                {
                  path: '',
                  component: () => import(
                    'pages/_locale/dashboard/index.vue'
                    ),
                },
                {
                  path: 'group',
                  component: () => import(
                    'pages/_locale/dashboard/group.vue'
                    ),
                  children: [
                    {
                      path: 'create',
                      component: () => import(
                        'pages/_locale/dashboard/group/create.vue'
                        ),
                    },
                    {
                      path: ':id',
                      component: () => import(
                        'layouts/RouterView.vue'
                        ),
                      children: [
                        {
                          path: '',
                          component: () => import(
                            'pages/_locale/dashboard/group/_id/index.vue'
                            ),
                        },
                        {
                          path: 'edit',
                          component: () => import(
                            'pages/_locale/dashboard/group/_id/edit.vue'
                            ),
                        },
                        {
                          path: 'delete',
                          component: () => import(
                            'pages/_locale/dashboard/group/_id/delete.vue'
                            ),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    // Always leave this as last one,
    // but you can also remove it
    {
      path: '*',
      component: () => import('pages/Error404.vue'),
    },
  ]
}
