import dataGetters from '../dataGetters'
import dataMutations from '../dataMutations'
import { api } from 'boot/axios'

export default {
  namespaced: true,
  actions: {
    async me ({ commit }) {
      commit('setLoading', true)

      let response = null
      try {
        response = await api.get(
          '/me',
          {
            headers: {
              'x-show-error': false,
              'x-show-loading': false,
            },
          },
        )
      } catch (error) {
        // console.error(error)
        commit('setLoading', false)

        throw error
      }

      commit('setData', response.data)
      commit('setLoading', false)
    },
    async signIn ({ commit }, params) {
      commit('setLoading', true)

      let response = null
      try {
        response = await api.post(
          '/sign-in',
          params,
        )
      } catch (error) {
        // console.error(error)
        commit('setLoading', false)

        throw error
      }
    },
    async signUp ({ commit }, params) {
      commit('setLoading', true)

      let response = null
      try {
        response = await api.post(
          '/sign-up',
          params,
        )
      } catch (error) {
        // console.error(error)
        commit('setLoading', false)

        throw error
      }
    },
    async resetPassword ({ commit }, params) {
      commit('setLoading', true)

      let response = null
      try {
        response = await api.post(
          '/reset-password',
          params,
        )
      } catch (error) {
        // console.error(error)
        commit('setLoading', false)

        throw error
      }
    },
    async setPassword ({ commit }, params) {
      commit('setLoading', true)

      let response = null
      try {
        response = await api.post(
          '/set-password',
          params,
        )
      } catch (error) {
        // console.error(error)
        commit('setLoading', false)

        throw error
      }
    },
  },
  mutations: {
    ...dataMutations,
  },
  getters: {
    ...dataGetters,
  },
  state () {
    return {
      data: {},
      loading: false,
    }
  },
}
