import getActions from '../getActions'
import postActions from '../postActions'
import dataGetters from '../dataGetters'
import dataMutations from '../dataMutations'

export default {
  namespaced: true,
  actions: {
    ...getActions,
    ...postActions,
  },
  mutations: {
    ...dataMutations,
  },
  getters: {
    ...dataGetters,
  },
  state () {
    return {
      data: [],
      loading: false,
      meta: {},
      moduleSlug: 'group',
    }
  },
}
