import { api } from 'boot/axios'

export default {
  async create ({ commit, dispatch, state }, params) {
    commit('setLoading', true)

    params = await dispatch('__beforeSave', params)
    for (const [key, value] of Object.entries(params)) {
      if ('' === value) {
        params[key] = null
      }
    }

    let response = null
    try {
      response = await api.post(
        `/${state.moduleSlug}`,
        params,
      )
    } catch (error) {
      // console.error(error)
      commit('setLoading', false)

      throw error
    }

    params.id = response.data.data.id
    commit('setDatum', response.data)
    commit('setLoading', false)

    return response
  },
  async update ({ commit, dispatch, state }, params) {
    commit('setLoading', true)

    params = await dispatch('__beforeSave', params)
    for (const [key, value] of Object.entries(params)) {
      if ('' === value) {
        params[key] = null
      }
    }

    let response = null
    try {
      response = await api.patch(
        `/${state.moduleSlug}/${params.id}`,
        params,
      )
    } catch (error) {
      // console.error(error)
      commit('setLoading', false)

      throw error
    }

    params.id = response.data.data.id
    commit('setDatum', response.data)
    commit('setLoading', false)

    return response
  },
  async delete ({ commit, state }, { id }) {
    commit('setLoading', true)

    let response = null
    try {
      response = await api.delete(
        `/${state.moduleSlug}/${id}`,
      )
    } catch (error) {
      // console.error(error)
      commit('setLoading', false)

      throw error
    }

    commit('deleteDatum', id)
    commit('setLoading', false)

    return response
  },
  async __beforeSave ({ commit }, params) {
    // Default implementation
    return params
  },
}
